import React from 'react';
import "./index.css";
import Emailform from "../components/Emailform";
import page1 from "../images/0001.jpg";
import page2 from "../images/0002.jpg";
import page3 from "../images/0003.jpg";
import page4 from "../images/0004.jpg";

function index() {

  return (
    <div className="indexBackground">
      <div className="indexContainer">
      <div className="index">
        <img src={page1} alt="Main info" className="page1"/>
    <Emailform />
      </div>
    <img src={page2} alt="Product info" className="page2"/>
    <img src={page3} alt="Product info" className="page3"/>
    <img src={page4} alt="Product info" className="page4"/>
 </div>
 </div>
  )
}

export default index