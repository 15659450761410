import React from 'react'
import "./productinfo.css";
import page5 from '../images/0005.jpg';
import page6 from '../images/0006.jpg';
import page7 from '../images/0007.jpg';
import page8 from '../images/0008.jpg';
import page9 from '../images/0009.jpg';
import page10 from '../images/0010.jpg';


function ProductInfo() {
  return (
    <div className="productinfocontent">
<img src={page5} alt="Product info" className="page5" />
<img src={page6} alt="Product info" className="page6" />
<img src={page7} alt="Product info" className="page7" />
<img src={page8} alt="Product info" className="page8" />
<img src={page9} alt="Product info" className="page9" />
<img src={page10} alt="Product info" className="page10" />
    </div>
  )
}

export default ProductInfo
