import React from 'react';
import fbimg from "../images/followfb.png";
import instaimg from "../images/insta.png";
import "./successpage.css";
import ProductInfo from "../pages/ProductInfo";

const SuccessPage = () => {  
  return (
    <div className="background">
        <div className="container">
<div className="links">
<a href="https://www.facebook.com/groups/832637904718960" target="blank" rel="noopener noreferrer">
      <img src={fbimg} alt="Facebook" className="fbimg"/>
      </a>

<a href="https://www.instagram.com/danebergsengineering/?hl=en" target="blank" rel="noopener noreferrer">
    <img src={instaimg} alt="Instagram" className="instaimg"/>
    </a>
</div>
      </div>
      <ProductInfo />

      {/* <div className="footer">
<a href="https://www.facebook.com/groups/832637904718960" target="blank" rel="noopener noreferrer">
      <img src={fbimg} alt="Facebook" className="ffbimg"/>
      </a>

<a href="https://www.instagram.com/danebergsengineering/?hl=en" target="blank" rel="noopener noreferrer">
    <img src={instaimg} alt="Instagram" className="finstaimg"/>
    </a>
</div> */}

     </div> 
  );
};

export default SuccessPage;