import React from 'react';
import { useNavigate} from 'react-router-dom';
import "./emailform.css";

export default function EmailForm () {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const target = e.target;

    const subscriberEmail = target.email.value;

    fetch("./.netlify/functions/triggerSubscribeEmail", {
      method: "POST",
      body: JSON.stringify({
        subscriberEmail: subscriberEmail,
        inviteeEmail: "info@plcify.io",
      }),
    });
    navigate('/success');
  };

return(
  <div className="content">
  <form onSubmit={handleSubmit}>
    <input
      type="email"
      placeholder="your@email.com"
      name="email"
      autoComplete="on"
    />
    <button type="submit">
      Keep me informed about this product
    </button>
  </form>
</div>
);
}