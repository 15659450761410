import React, {useEffect} from 'react';
import Index from "./pages/index";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SuccessPage from './pages/SuccessPage';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-C0XMKQ8V4M";
ReactGA.initialize(TRACKING_ID);

const App = () => {

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Home Page" });
},[]);

  return (
    <BrowserRouter>
    <div>
      <Routes>
        <Route path="/" element={<Index />} />
    <Route path="/success" element={<SuccessPage />} />
    </Routes>

    </div>
    </BrowserRouter>
  )
}

export default App
